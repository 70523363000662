<template>
    <div>
        <section class="login-page">
            <div class="login-section">
                <div class="container-fluid">
                    <div class="row align-items-center justify-content-end">
                        <div class="col-md-6">
                            <div class="login-content">
                                <h1 class="text-white mb-4">INDUSTRY TRAINING</h1>
                                <p class="text-white mb-4">
                                    Sign Up now to begin your restaurant and hospitality industry training - either with your company or as an individual, we have course options and certificate programs to fit your needs and help advance your career goals!
                                </p>
                                <router-link to="/signup" class="btn login-btn pe-5 ps-5 w-auto">SIGN UP</router-link>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="login-content">
                                <div class="form-section">
                                    <div class="login-logo text-center mb-4">
                                        <img :src="`/img/logos/${$currentPlatform}_logo.png`"/>
                                    </div>
                                    <div class="login-title mb-4">
                                        <h2>Welcome Back!</h2>
                                        <p>
                                            Please login with your user information below.
                                        </p>
                                    </div>
                                    <login-input></login-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <the-footer/>
    </div>
</template>
<script>
import LoginInput from "@/views/Pages/dra_LoginInput.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import TheFooter from '@/views/Layout/AppFooter.vue'
export default {
    components: {
        LoginInput,
        TheFooter,
    },
    data() {
        return {
            loading: false,
            form: {
                username: "",
                password: "",
            },
            errors: {
                invalid: "",
            },
        };
    },
    created: function () {
        if (this.$route.query.email && this.$route.query.password) {
            this.form.username = this.$route.query.email;
            this.form.password = this.$route.query.password;

            this.onSubmit();
        }
    },
    methods: {
        onSubmit() {
            this.loading = true;
            this.$http
                .post("user/login", {
                    email: this.form.username,
                    password: this.form.password,
                })
                .then((resp) => {
                    localStorage.setItem("hot-token", resp.data.token);
                    localStorage.setItem("hot-user", resp.data.role);
                    localStorage.setItem("hot-logged-user", resp.data.user_id);
                    localStorage.setItem("hot-user-full-name", resp.data.full_name);
                    let headers = {
                        authorization: "Bearer " + resp.data.token,
                        "content-type": "application/json",
                    };
                    this.$http.defaults.headers.authorization =
                        "Bearer " + resp.data.token;
                    let admin = "";
                    let level = "";

                    switch (resp.data.role) {
                        case "super-admin":
                            admin = "super_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        case "company-admin":
                            admin = "admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/data",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    if (resp.data.level) {
                                        localStorage.setItem("hot-company-level", "parent");
                                    } else {
                                        localStorage.setItem("hot-company-level", "child");
                                    }
                                    localStorage.setItem("hot-admin-id", resp.data.admin_id);
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-company-name", resp.data[0].name);
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "manager":
                            admin = "manager";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "employee":
                            admin = "employee";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "employees/user_data",
                                    {
                                        user_name: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    if (resp.data[0].employee_status == 0) {
                                        this.errors.invalid = "Account is Deactivated by Admin..!!";
                                    } else {
                                        this.$router.push("/dashboard");
                                    }
                                });
                            break;
                        case "sub-admin":
                            admin = "sub_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });
                            break;
                        default:
                            //this.$router.push("/login");
                            this.errors.invalid = "Not Valid..!!";
                    }
                })
                .catch(function (error) {
                    let errorText = "Something went wrong! Please try again later.";
                    if (error.response && error.response.status === 422) {
                        errorText = error.response.data.message;
                    }
                    self.processing = false;
                    Swal.fire({
                        title: "Error!",
                        html: errorText,
                        icon: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
<style scoped>
html {
    height: 100%;
}

body {
    font-family: "Poppins", sans-serif;
    min-height: 100%;
    color: #000000;
    background-color: #fcfcfc;
}

a {
    text-decoration: none;
}

a {
    color: #0050bf;
}

/****Login page css start ****/
.login-page {
    background-image: url("/images/login-bg.png");
    min-height: 100vh;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-form label {
    font-weight: 500;
}

.login-section {
    height: 90vh;
    display: flex;
    align-items: center;
}

.login-control {
    position: relative;
}

.login-control span.has-icon {
    position: absolute;
    top: 15px;
    left: 23px;
    color: #d6d6d6;
    font-size: 18px;
}

.login-control input.form-control {
    height: 55px;
    padding-left: 50px;
    border: 1px solid #c5c5c5;
    border-radius: 30px;
}

.login-control input.form-control:focus {
    box-shadow: none;
    /* border-bottom: 1px solid #ffa61a; */
}

.form-section {
    padding: 50px;
}

.login-btn {
    background-color: #a8312d;
    color: #ffffff;
    padding: 10px;
    border-radius: 30px;
    width: 100%;
}

.login-form a {
    color: #000000;
}

.signup-link {
    color: #000000;
    text-align: center;
    padding-top: 30px;
    text-transform: uppercase;
}

.signup-link a {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: underline;
}

.login-btn:hover {
    color: #ffffff;
}

.login-control span.view-password {
    position: absolute;
    top: 16px;
    right: 12px;
    color: #a5a5a5;
    cursor: pointer;
}

.loginlog {
    position: absolute;
    right: 5%;
    top: 0;
    width: 200px;
}

.form-section {
    background-color: #fff;
    border-radius: 15px;
}

.login-section > .container-fluid {
    padding-right: 60px;
    padding-left: 60px;
}

/****Login page css end ****/

@media screen and (max-width: 767px) {
    .login-page {
        padding-top: 30px;
        padding-bottom: 20px;
        height: auto;
        min-height: 100%;
    }

    .login-section > .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
    }

    .login-content {
        margin-bottom: 20px;
    }

    .form-section {
        padding: 20px;
    }

    .login-section {
        height: auto;
    }

    .login-content p {
        font-size: 14px;
    }
}
@media only screen and (max-width:1536px){
    .form-section {
        padding: 20px !important;
    }
}
</style>
